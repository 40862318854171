<template>
    <div>
        <div class="card-group">
            <div
                v-for="(item, index) in listData"
                :key="index"
                class="card-item"
                @click="goToLink(item)"
            >
                <v-progress-circular
                    v-if="!showData"
                    indeterminate
                    color="orange"
                />
                <v-card
                    v-if="showData"
                    class="mx-auto"
                    width="280px"
                >
                    <v-img
                        class="white--text align-end"
                        width="280px"
                        height="210px"
                        :src="image(item.image)"
                    />
                    <div
                        class="card-content"
                        :class="{ 'border': captionOpen }"
                    >
                        <div
                            class="card-title"
                            :class="{ 'title-center': !captionOpen ,'title-justify': captionOpen }"
                        >
                            {{ item.title }}
                        </div>
                        <div
                            v-if="captionOpen"
                            class="card-caption"
                        >
                            {{ item.description }}
                        </div>
                    </div>
                </v-card>
            </div>
        </div>
        <div class="more">
            <div
                v-if="more===false"
                class="more-text mt-5"
                @click="handleMore"
            >
                <span class="color-lightgrey">{{ $t('ThreeBlock.more') }}</span>
                <v-icon
                    large
                    color="selectGray darken-2"
                >
                    mdi-chevron-down
                </v-icon>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    components: {},
    props: {
        showData: {
            type: Boolean,
            default: false,
        },
        captionOpen: {
            type: Boolean,
            default: true,
        },
        blockData: {
            type: Object,
            default: () => ({}),
        },
        routerName: {
            type: String,
            default: () => '',
        },
        moreRouterLink: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            more: false,
            screenWidth: document.body.clientWidth,
        };
    },
    computed: {
        listData() {
            if (this.more) {
                return this.blockData.items;
            }
            if (this.screenWidth > 1310) {
                return _.take(this.blockData.items, 3);
            }
            if (this.screenWidth <= 1310 && this.screenWidth > 659) {
                return _.take(this.blockData.items, 2);
            }
            return _.take(this.blockData.items, 1);
        },
    },
    watch: {
        screenWidth() {

        },
    },
    created() {},
    mounted() {
        window.addEventListener('resize', () => (() => {
            window.screenWidth = document.body.clientWidth;
            this.screenWidth = window.screenWidth;
        })());
    },
    methods: {
        goToLink(item) {
            if (this.blockData.type === 'link') {
                window.open(item.link);
            } else if (this.blockData.type === 'router') {
                this.$router.push({ name: this.routerName, params: { id: item.id } });
            }
        },
        handleMore() {
            if (this.moreRouterLink) {
                this.$router.push({ name: this.moreRouterLink });
            } else {
                this.more = true;
            }
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.card-group{
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 280px);
  grid-gap: 40px;
  .card-item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    text-decoration:none;
    margin-top: 30px;
    margin-right: 40px;
    cursor: pointer;
    .card-content{
      padding: 15px;
      line-height: 2;
      letter-spacing: 0.1em;
      .card-title{
        font-size: 21px;
        font-weight: 700;
        color: $color-blue;
        overflow:hidden;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:1;
      }
      .card-caption{
        font-size: 16px;
        font-weight: 700;
        text-align: justify;
        overflow:hidden;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
      }
    }
  }
  .card-item:nth-child(3n+3){
    margin-right: 0;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .v-sheet.v-card {
    border-radius: 0;
  }
}

.more{
  display: flex;
  justify-content: center;
  .more-text{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    span{
      font-size: 21px;
      font-weight: 700;
      letter-spacing: 0.1em;
      margin-bottom: 0;
      color: $color-orange;
    }
    .v-icon{
      color: $color-orange;
    }
  }

}
//圖片暫時顏色
.v-image{
  background-color: #d9d9d9;
}

.border{
  height: 140px;
  border: 1px solid $color-orange;
}

.title-center{
  text-align: center;
}

.title-justify{
  text-align: justify;
}

@media screen and (max-width: 1400px) {
  .card-group{
    justify-content: space-evenly;
    .card-item{
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 960px) {
  .card-group{
    flex-direction: column;
  }
}

</style>
